import { SerializableObject } from "./serializable-object";

export class BrandCampaignPartner extends SerializableObject {
  id!: number;
  companyName!: string;
}

export class BrandCampaignPartnerList extends SerializableObject {
  items!: BrandCampaignPartner[];
  totalCount!: number;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "items":
        return propertyValue.map((data: any) => new BrandCampaignPartner(data));
      default:
        return propertyValue;
    }
  }
}
