import { CampaignPartnerStatus } from "../enums/campaign.enums";

export class BrandCampaignInvitedPartner {
  // TODO: Set as `public readonly…`
  constructor(
    public status: CampaignPartnerStatus,
    public partnerImportId: number,
    public partnerId: number,
    public companyName: string,

    public firstContactPersonFirstName: string,
    public firstContactPersonLastName: string,
    public firstContactPersonEmail: string,

    public secondContactPersonFirstName: string,
    public secondContactPersonLastName: string,
    public secondContactPersonEmail: string,
  ) {}
}
