import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { FileDescription } from "../../../../shared/entities/file-description";
import { AppLocale } from "../../../../shared/models/appData";
import {
  BrandCampaignPartnerStatusAndEngagementChart,
  BrandInsightPartner,
} from "../../../../shared/models/brandCampaignInsight";
import { BrandCampaignInvitedPartner } from "../../../../shared/models/brandCampaignInvitedPartner";
import {
  BrandCampaignPartner,
  BrandCampaignPartnerList,
} from "../../../../shared/models/brandCampaignPartner";
import { BrandCampaignPartnerImport } from "../../../../shared/models/brandCampaignPartnerImport";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { HttpClient } from "../../../../shared/services/http-client";
import { FileResponse } from "../../../../shared/services/responses/file-response";

@Injectable({ providedIn: "root" })
export class BrandCampaignPartnersService {
  private path = "brand";

  constructor(
    private http: HttpClient,
    private brandService: BrandService,
  ) {}

  getBrandCampaignPartners(
    campaignId: number,
    offset = 0,
    limit = 50,
  ): Observable<BrandCampaignPartnerList> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .get(
        `${this.path}/${brandId}/campaign/${campaignId}/partner?offset=${offset}&limit=${limit}`,
      )
      .pipe(
        map(({ body }) => {
          const list = new BrandCampaignPartnerList();

          list.totalCount = body._meta ? body._meta.totalCount : 0;
          list.items = body.items.map(
            (partnerData: any) => new BrandCampaignPartner(partnerData),
          );

          return list;
        }),
      );
  }

  public getPartnersEngagement(
    campaignId: number,
  ): Observable<BrandCampaignPartnerStatusAndEngagementChart> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .get<BrandCampaignPartnerStatusAndEngagementChart>(
        `${this.path}/${brandId}/campaign/${campaignId}/partners_engagement`,
      )
      .pipe(
        map(({ body }) => body as BrandCampaignPartnerStatusAndEngagementChart),
      );
  }

  updateBrandCampaignPartner(
    campaignId: number,
    brandInsightPartnerId: number,
    brandNotes: string | null,
    shortUrl: string,
    shortUrlPrefix: string,
  ): Observable<BrandInsightPartner> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .put(
        `${this.path}/${brandId}/campaign/${campaignId}/partner/${brandInsightPartnerId}`,
        {
          brandNotes: brandNotes,
          shortUrl: shortUrl,
          shortUrlPrefix: shortUrlPrefix,
        },
      )
      .pipe(map(({ body }) => new BrandInsightPartner(body)));
  }

  // Used for partner export
  public exportBrandCampaignPartners(
    campaignId: number,
    fields: any[],
    partnerIds: any[],
    format: string,
  ): Observable<FileResponse> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `${this.path}/${brandId}/campaign/${campaignId}/insights_export_partners`,
        {
          responseType: "arraybuffer",
          headers: {},
          export_fields: fields,
          export_partner_ids: partnerIds,
          format: format,
        },
      )
      .pipe(map(({ body }) => body));
  }

  public async getBrandCampaignPartnersTemplateUrl(
    campaignId: number,
    locale: AppLocale,
  ): Promise<string> {
    const brandId = this.brandService.currentBrandId;
    return lastValueFrom(
      this.http.get(
        `${this.path}/${brandId}/campaign/${campaignId}/import_partners_template?locale=${locale}`,
      ),
    ).then((response) => {
      const body: FileDescription = response.body;
      return body.fileUrl;
    });
  }

  checkAndGetBatchImportsIfReady(
    campaignId: number,
    batchId: number,
  ): Observable<BrandCampaignPartnerImport> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .get(
        `${this.path}/${brandId}/campaign/${campaignId}/batch/${batchId}/imported_partners`,
      )
      .pipe(map(({ body }) => new BrandCampaignPartnerImport(body)));
  }

  getUploadBrandCampaignPartnersTemplateUrl(
    campaignId: number,
    locale: AppLocale,
  ) {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `${this.path}/${brandId}/campaign/${campaignId}/import_partners_template?locale=${locale}`,
    );
  }

  importBrandCampaignPartnerList(
    campaignId: number,
    partnerIds: number[],
    importListIds: number[],
  ): Observable<BrandInsightPartner> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `${this.path}/${brandId}/campaign/${campaignId}/import_partner_list`,
        {
          partner_ids: partnerIds,
          partner_import_ids: importListIds,
        },
      )
      .pipe(map(({ body }) => new BrandInsightPartner(body)));
  }

  inviteBrandCampaignWaitingPartners(
    campaignId: number,
  ): Observable<BrandInsightPartner> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `${this.path}/${brandId}/campaign/${campaignId}/invite_waiting_partners`,
        {},
      )
      .pipe(map(({ body }) => new BrandInsightPartner(body)));
  }

  clearBrandCampaignUninvitedPartners(campaignId: number): Observable<void> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `${this.path}/${brandId}/campaign/${campaignId}/clear_uninvited_partners`,
        {},
      )
      .pipe(map(() => {}));
  }

  getInvitedPartners(
    campaignId: number,
  ): Observable<BrandCampaignInvitedPartner[]> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .get(`${this.path}/${brandId}/campaign/${campaignId}/invited_partners`)
      .pipe(
        map(({ body }) =>
          body.map(
            (data: any) =>
              new BrandCampaignInvitedPartner(
                data.status,
                data.partnerImportId,
                data.partnerId,
                data.companyName,
                data.firstContactPersonFirstName,
                data.firstContactPersonLastName,
                data.firstContactPersonEmail,
                data.secondContactPersonFirstName,
                data.secondContactPersonLastName,
                data.secondContactPersonEmail,
              ),
          ),
        ),
      );
  }

  deleteBrandCampaignPartnerInvitation(
    campaignId: number,
    partnerImportId: number,
  ): Observable<any> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .del(
        `${this.path}/${brandId}/campaign/${campaignId}/invited_partners/${partnerImportId}`,
      )
      .pipe(map(() => {}));
  }

  updateBrandCampaignPartnerStatus(
    campaignId: number,
    partnerId: number,
    partnerStatus: string,
  ): Observable<any> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .put(
        `${this.path}/${brandId}/campaign/${campaignId}/partner/${partnerId}`,
        { status: partnerStatus },
      )
      .pipe(map(({ body }) => body));
  }

  deleteBrandCampaignPartner(
    campaignId: number,
    partnerId: number,
  ): Observable<any> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `${this.path}/${brandId}/campaign/${campaignId}/partner/${partnerId}`,
      )
      .pipe(map(({ body }) => body));
  }
}
