import { Address } from "./address";
import { SerializableObject } from "./serializable-object";

export enum PartnerMatchType {
  NoMatch = "PartnerMatchType",
  PartialMatchName = "PARTIAL_MATCH_NAME",
  PartialMatchAddress = "PARTIAL_MATCH_ADDRESS",
  PartialMatchNameAddress = "PARTIAL_MATCH_NAME_ADDRESS",
}

export class BrandCampaignPartnerImport extends SerializableObject {
  isImportFinished!: boolean;
  progress!: number;
  partnerImports!: BrandCampaignPartnerImportRow[];
  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "partnerImports":
        return propertyValue.map(
          (data: any) => new BrandCampaignPartnerImportRow(data),
        );
      default:
        return propertyValue;
    }
  }
}

export class BrandCampaignPartnerImportRow extends SerializableObject {
  id!: number;
  address!: Address;
  companyName!: string;
  matchType!: PartnerMatchType;
  partnerMatches!: BrandCampaignPartnerImportPartnerMatch[];
  checked!: boolean;
  noneOfTheAboveSelected!: boolean;
  storeName!: string;
  firstContactPersonFirstName!: string;
  firstContactPersonLastName!: string;
  firstContactPersonEmail!: string;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "partnerMatches":
        return propertyValue.map(
          (data: any) => new BrandCampaignPartnerImportPartnerMatch(data),
        );
      default:
        return propertyValue;
    }
  }
}

export class BrandCampaignPartnerImportPartnerMatch extends SerializableObject {
  id!: number;
  matchType!: PartnerMatchType;
  checked!: boolean;
  similarPercentage!: number;
  matchedAddress!: BrandCampaignPartnerImportPartnerMatchAddress | null;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "matchedAddress":
        if (!propertyValue) {
          return null;
        }
        return new BrandCampaignPartnerImportPartnerMatchAddress(propertyValue);
      default:
        return propertyValue;
    }
  }
}

export class BrandCampaignPartnerImportPartnerMatchAddress extends SerializableObject {
  id!: number;
  street!: string;
  number!: string;
  postalCode!: string;
  city!: string;
  country!: string;
}
