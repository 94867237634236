import { SerializableObject } from "./serializable-object";

import { CampaignPartnerStatus, CampaignType } from "../enums/campaign.enums";
import { PartnerLogoStatus } from "../enums/partner.enums";

export class BrandCampaignInsight extends SerializableObject {
  campaignType!: CampaignType;
  currencySymbol!: string;
  includePos!: boolean;
  campaignDuration!: number;
  campaignTotalBudget!: number;
  campaignPendingBudget!: number;
  campaignSpentBudget!: number;
  facebookAdsBudget!: number;
  useFacebookAds!: boolean;
  googleAdsBudget!: number;
  useGoogleAds!: boolean;
  useLinkedin!: boolean;
  promoAdsEnabled!: boolean;
  publisherPlatformInstagram!: boolean;
  averagePostsPerDay!: number;
  activePartnersPerDay!: number;
  totalEngagedPartners!: number;
  totalPartners!: number;
  totalAds!: number;
  totalFacebookAds!: number;
  totalGoogleAds!: number;
  totalFacebookPosts!: number;
  totalPublishedFacebookAds!: number;
  totalPublishedGoogleAds!: number;
  totalPublishedFacebookPosts!: number;
  totalPublishedInstagramPosts!: number;
  totalPublishedLinkedinPosts!: number;
  totalParticipants!: number;
  totalPosOrders!: number;
  totalPosOrdersAmount!: number;
  totalLinkClicks!: number;
  adsStats!: BrandInsightAdStats;
  googleAdsStats!: GoogleAdStats;
  facebookAdsStats!: FacebookAdStats;
  facebookPostsStats!: FacebookPostStats;
  instagramPostsStats!: InstagramPostStats;
  linkedinPostsStats!: LinkedInPostStats;
  totalPosts!: BrandInsightTotalPosts;
  partners!: BrandInsightPartner[];
  showDailyCharts!: boolean;
  engagementChart!: BrandCampaignInsightEngagementChart;
  partnerStatusChart!: BrandCampaignInsightPartnerStatusChart;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "partners":
        return propertyValue.map(
          (partner: any) => new BrandInsightPartner(partner),
        );
      default:
        return propertyValue;
    }
  }
}

export interface BrandCampaignInsightEngagementChart {
  readonly downloadedContent: number;
  readonly manuallyPlanned: number;
  readonly calendarFullyAccepted: number;
  readonly calendarPartiallyAccepted: number;
  readonly publishedGoogleAdsAutomatically?: number;
}

export interface BrandCampaignInsightPartnerStatusChart {
  accepted: number;
  cancelled: number;
  invited: number;
  pendingValidation: number;
  validated: number;
  engaged: number;
}

export interface BrandCampaignPartnerStatusAndEngagementChart {
  engagementChart: BrandCampaignInsightEngagementChart;
  partnerStatusChart: BrandCampaignInsightPartnerStatusChart;
  totalEngagedPartners: number;
}

export class BrandCampaignInsightChart extends SerializableObject {
  posOrdersPerDayChart!: number[];
  facebookPostsDailyMetricsChart!: FacebookPostStats[];
  instagramPostsDailyMetricsChart!: InstagramPostStats[];
  googleAdsDailyMetricsChart!: GoogleAdStats[];
  facebookAdsDailyMetricsChart!: FacebookAdStats[];
  linkedinPostsDailyMetricsChart!: LinkedInPostStats[];
}

export interface BrandInsightPartnerCampaign {
  id: number;
  name: string;
}

export interface BrandInsightTotalPosts {
  manual: number;
  calendar: number;
  posted: number;
  scheduled: number;
  total: number;
  totalImages: number;
  totalVideos: number;
  totalCarousel: number;
  totalGif: number;
}

export interface BrandInsightAdStats {
  spend: number;
  impressions: number;
  inlinePostEngagement: number;
  reach: number;
  brandTotalCost: number;
  partnerTotalCost: number;
}

export interface GoogleAdStats {
  impressions: number;
  inlinePostEngagement: number;
  linkClicks: number;
  views: number;
  conversions: number;
  spend: number;
}

export interface FacebookAdStats {
  impressions: number;
  inlinePostEngagement: number;
  linkClicks: number;
  views: number;
  spend: number;
}

export interface FacebookPostStats {
  clicks: number;
  comments: number;
  impressions: number;
  likes: number;
  shares: number;
}

export interface InstagramPostStats {
  instagramEngagement: number;
  instagramImpressions: number;
  instagramReach: number;
  instagramVideoViews: number;
}

export interface LinkedInPostStats {
  engagement: number;
  clicks: number;
  comments: number;
  impressions: number;
  likes: number;
  shares: number;
}

export class BrandInsightPartner extends SerializableObject {
  id!: number;
  addressCity!: string | null;
  addressCountry!: string | null;
  addressPostalCode!: string | null;
  addressStreetAndNumber!: string | null;
  amountCalls!: number;
  amountEmails!: number;
  brandNotes!: string | null;
  companyAdditionalInfo!: string;
  companyName!: string;
  connectedToFacebook!: boolean;
  contactEmails!: string;
  contactFirstName!: string;
  contactLastName!: string;
  contactPhoneNumber!: string;
  contactTitle!: string;
  facebookPostsClicks!: number;
  facebookPostsComments!: number;
  facebookPostsImpressions!: number;
  facebookPostsLikes!: number;
  facebookPostsPublished!: number;
  facebookPostsScheduled!: number;
  facebookPostsShares!: number;
  facebookUrl!: string | null;
  fbAdRightsStatus!: string;
  hasApprovedContentCalendar!: "Yes" | "No";
  isProspect!: boolean;
  linkedinPostsClicks!: number;
  linkedinPostsComments!: number;
  linkedinPostsEngagements!: number;
  linkedinPostsImpressions!: number;
  linkedinPostsLikes!: number;
  linkedinPostsPublished!: number;
  linkedinPostsScheduled!: number;
  linkedinPostsShares!: number;
  logoStatus!: PartnerLogoStatus;
  orderedPos!: boolean;
  percentageCompleted!: string;
  salesRep!: string | null;
  shortUrl!: string;
  shortUrlPrefix!: string;
  socialPalsId!: number;
  status!: CampaignPartnerStatus;
  totalAdBudget!: number;
  totalAdClicks!: number;
  totalAdImpressions!: number;
  totalAdSpend!: number;
  totalAssetsDownloaded!: number;
  totalFacebookAdsBudget!: number;
  totalFacebookAdsClicks!: number;
  totalFacebookAdsEngagement!: number;
  totalFacebookAdsImpressions!: number;
  totalFacebookAdsReach!: number;
  totalFacebookAdsSpent!: number;
  totalFacebookPaidAds!: number;
  totalFacebookPaidAdsBudget!: number;
  totalFacebookPaidAdsSpend!: number;
  totalGoogleAdsSpent!: number;
  totalGoogleBudget!: number;
  totalGoogleClicks!: number;
  totalGoogleImpressions!: number;
  totalGooglePaidAds!: number;
  totalGooglePaidAdsBudget!: number;
  totalGooglePaidAdsSpend!: number;
  totalGoogleViews!: number;
  totalInstagramPostsEngagement!: number;
  totalInstagramPostsImpressions!: number;
  totalInstagramPostsPublished!: number;
  totalInstagramPostsReach!: number;
  totalInstagramPostsScheduled!: number;
  totalInstagramPostsVideoViews!: number;
  totalParticipants!: number;
  totalPostsImpressions!: number;
  totalPostsPublished!: number;
  totalPostsScheduled!: number;
  totalPublishedAds!: number;
  totalPublishedFacebookAds!: number;
  totalPublishedGoogleAds!: number;
  totalScheduledAds!: number;
  totalScheduledFacebookAds!: number;
  totalScheduledGoogleAds!: number;
  whichCalendarAccepted!: string | null;
}

export type BrandInsightPartnerProp = keyof BrandInsightPartner;
